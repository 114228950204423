//Export functions

export function useLoyaltyProgram() {
  const {requestHeaders, requestPath} = utilRequests();

  const {user} = useUser();

  const loyaltyRanks = ref(null);
  const userLoyalty = ref(null);
  const nextRank = ref(null);
  const missingPoints = ref(null);

  const compare = (a, b) => {
    const optionA = a.minimumPoints;
    const optionB = b.minimumPoints;

    let comparison = 0;
    if (optionA > optionB) {
      comparison = 1;
    } else if (optionA < optionB) {
      comparison = -1;
    }
    return comparison;
  };

  // Fetch loyalty ranks
  const getLoyaltyRanks = () => {
    fetch(`${requestPath}/loyalty/rank`, {
      method: 'POST',
      ...requestHeaders.value,
      body: JSON.stringify({
        sort: [
          {
            field: 'minimumPoints',
            order: 'ASC',
          },
        ],
      }),
    })
      .then((res) => res.json())
      .then((json) => {
        json.loyaltyRankCollection.sort(compare);
        loyaltyRanks.value = json;
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  };

  // Fetch user loyalty
  const getUserLoyalty = () => {
    const {user} = useUser();

    if (
      user &&
      user.value &&
      user.value.customFields &&
      user.value.customFields.loyaltyOptIn
    ) {
      fetch(`${requestPath}/account/loyalty`, {
        method: 'POST',
        progress: false,
        ...requestHeaders.value,
      })
        .then((res) => res.json())
        .then((json) => {
          // json.loyaltyRankHistory.sort(compareDate)
          userLoyalty.value = json;
        })
        .catch((error) => {
          console.error('Error:', error);
        });
    }
  };

  //Find next possible rank
  const getNextRank = () => {
    if (
      userLoyalty &&
      userLoyalty.value &&
      userLoyalty.value.activeLoyaltyRank &&
      loyaltyRanks.value
    ) {
      const currentRank = userLoyalty.value.activeLoyaltyRank;
      const findNextRank = loyaltyRanks.value.loyaltyRankCollection.find(
        (rank) => rank.minimumPoints > currentRank.minimumPoints
      );
      nextRank.value = findNextRank;
    }
  };

  const getMissingPoints = () => {
    if (nextRank.value && userLoyalty.value) {
      missingPoints.value =
        nextRank.value.minimumPoints - userLoyalty.value.currentPoints;
    }
  };

  // refreshUser();

  getLoyaltyRanks();

  getUserLoyalty();

  watchEffect(() => {
    getNextRank();
  });
  watchEffect(() => {
    getMissingPoints();
  });

  const optIn = () => {
    fetch(`${requestPath}/account/loyalty/optin`, {
      method: 'POST',
      ...requestHeaders.value,
      body: JSON.stringify({
        loyaltyOptIn: true,
      }),
    })
      .then((res) => res.json())
      .then((json) => {
        if (window) {
          window.location.reload();
        }
      });
  };

  return {
    userLoyalty,
    nextRank,
    missingPoints,
    loyaltyRanks,
    optIn,
  };
}
